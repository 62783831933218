import React, { useState, useEffect, useRef } from "react";
import { DotLoader } from "react-spinners";

import VanillaTilt from "vanilla-tilt";

const { buyNFT, fetchAll } = require("../lib/ethers").init(window.ethereum);

const BuyButton = ({ isVisible, buy, tokenId, isConnected }) => {
  return isVisible ? (
    <button
      className=" w-full  h-[100px] bg-[#FDF7E4] border border-fdf7e4 rounded self-center"
      onClick={
        isConnected
          ? () => buy(tokenId)
          : () => alert("Please connect to MetaMask")
      }
    >
      <p className="font-bold  Druk-Text text-[#1a1a1a]  text-[22px] md:text-[28px]  text-center pb-2 ">
        {isConnected ? "ACQUISTA ORA" : "Please connect to MetaMask"}
      </p>
    </button>
  ) : (
    <button className=" w-full  h-[89px] bg-[#FDF7E4] border border-fdf7e4 rounded self-center">
      <p className="font-bold  Druk-Text text-[#1a1a1a]  text-[22px] md:text-[28px]  text-center pb-2 ">
        {isConnected ? "NFT ACQUISTATO" : "Please connect to MetaMask"}
      </p>
    </button>
  );
};

/* const MintNft = (tokenURI) => { 

  mintNFT(tokenURI).then(console.log);
  
  }

const MintButton = ({ tokenURI }) => {  

return (

<button className=" w-full  h-[89px] bg-[#FDF7E4" onClick= {()=> mintNFT(tokenURI)}>
Mint

  </button> 
) }
 */

/* const SellButton = ({ isVisible, sell, tokenId }) => {
  return (
    isVisible && (
      <button
        className="btn btn-sm btn-outline-warning"
        onClick={() => sell(tokenId)}
      >
        SELL
      </button>
    )
  );
}; */
const PendingButton = ({ isPending }) => {
  return (
    isPending && (
      <div className="flex flex-col justify-center items-center">
        <DotLoader color="#FDF7E4" />
        <button
          style={{ opacity: 0.8 }}
          className="btn btn-sm btn-outline-info text-justify-center text-[#FDF7E4} Haptik text-[22px] md:text-[28px]  text-center pb-2"
          disabled
        >
          Processing ...{" "}
        </button>
      </div>
    )
  );
};
const Card = ({
  tokenId,
  tokenURI,
  name,
  owner,
  price,
  isActive,
  /* isListed, */
  image,
  anno,
}) => {
  const [isPending, loading] = useState(false);
  const [items, setItems] = useState([]);
  const [isListed, setIsListed] = useState(false);
  const [isConnected, setIsConnected] = useState();
  /* isListed = items[tokenId].isListed;
  console.log(isListed) */

  /* if (items !== undefined) {  
    console.log(items[tokenId].isListed) } */
  useEffect(() => {
    setIsConnected(typeof window.ethereum == "undefined");
    console.log(typeof window.ethereum == "undefined");
  }, []);

  const tiltNode = useRef(null); //Reference to the image element

  useEffect(() => {
    let tiltOptions = {
      transition: true,
      reverse: true,
      max: 15,
      speed: 500,
      glare: true,
      "max-glare": 1,
      easing: "cubic-bezier(.03,.98,.52,.99)",
    };

    VanillaTilt.init(tiltNode.current, tiltOptions);

    /*  return function cleanup() {
      tiltNode.current.vanillaTilt.destroy();
    } */

    fetchAll().then((response) => {
      setItems(response);
    });
  }, []);

  useEffect(() => {
    if (items.length !== 0) {
      console.log("items:", items[tokenId].isListed);
      setIsListed(items[tokenId].isListed);
      console.log(items);
    }
  }, [items, tokenId]);

  //const { tokenId, tokenURI, name, owner, price, isActive, isListed } = props;
  const buyToken = async () => {
    loading(true);
    try {
      await buyNFT(tokenId); // pass the token id to the buyNFT function
      loading(false);
      alert("Complimenti, sei il proprietario di un nuovo NFT!");
      window.location.reload();
    } catch (e) {
      console.error(e);
      loading(false);
    }
  };

  return (
    <div key={tokenURI} className="flex flex-col  mb-4 mx-5 items-center">
      <div
        id="ottieniNft"
        className=" w-2/3 font-bold  text-center Druk-Text text-[#FDF7E4] text-[35px] md:text-[50px] lg:text-[80px] mt-[0.8rem] mb-1"
      >
        OTTIENI IL TUO BIRROCCO NFT{" "}
      </div>
      <div>
        <img
          id="nft_image"
          src={image}
          className="w-[400px] rounded-[10px] my-4 sm:my-8"
          ref={tiltNode}
        />
      </div>

      <div className="" style={{ paddingBottom: 0 }}>
        <h5 className="">{name}</h5>

        <div className="d-flex flex-col justify-content-between align-content-center">
          <div>
            <PendingButton isPending={isPending} />
            <div
              id="container-buttons"
              className=" w-[400px] rounded-[10px] border border-fdf7e4 flex flex-col p-8"
            >
              <p className="w-full text-[#A7A69C] text-center Liberator text-[16px] md:text-[22px] self-end">
                {price} Birrocco {anno}{" "}
              </p>
              <p className="w-full h-[89px] text-[#FDF7E4] self-center text-center Druk-Text text-[22px] md:text-[28px] ">
                {price} 0.01 ETH
              </p>

              <BuyButton
                buy={buyToken}
                isVisible={isListed}
                isConnected={isConnected}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
